const debounce = (func: () => void, wait: number) => {
  let timeout: number;
  function inner() {
    const context = inner;
    // eslint-disable-next-line
    const args = arguments;
    const later = () => {
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
  }
  return inner;
};

export default debounce;
