/* eslint-disable import/prefer-default-export */

export const getRedirectURL = (link: string) => {
  // FTE now is in charge to create links correctly
  if (link.includes('://') || link.startsWith('//')) {
    return new URL(link);
  }

  return { href: '#' };
};
