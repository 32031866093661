import React, { useEffect, useState } from 'react';

import { setCookie } from '~/components/utils/cookies';
import { getCurrentDomain } from '~/components/utils/domains';
import { getRedirectURL } from '~/components/utils/links';
import { ICountryOption, ICountryOptions } from '~/interfaces/locale-selector';

const CountryOption: React.FC<ICountryOption> = ({
  activeEdition,
  country,
  links,
  trailingSlash,
  trackingLabels,
}) => {
  const isChecked = activeEdition === country.locale;
  const [redirectedURL, setRedirectedURL] = useState<{ href: string } | URL | null>(null);
  const activeClassName = isChecked ? ' locale-selector__option__name--active' : '';
  const optionIconClass = isChecked ? ' locale-selector__option__icon--visible' : '';

  useEffect(() => {
    if (!redirectedURL) {
      setRedirectedURL(getRedirectURL(country.link));
    }
  }, [country, links, trailingSlash]);

  const setCountryCodeCookie = (countryCode: string) => {
    if (typeof window !== 'undefined') {
      const expireDate = new Date();
      expireDate.setDate(expireDate.getDate() + 365);
      const currentDomain = getCurrentDomain();

      setCookie({
        name: 'FTR_User_Defined_Country_Code',
        value: countryCode,
        expireDate,
        secure: false,
        path: '/',
        domain: currentDomain,
      });
    }
  };

  const setHomepageCookie = (homepage: string) => {
    if (typeof window !== 'undefined') {
      const expireDate = new Date();
      expireDate.setDate(expireDate.getDate() + 365);

      setCookie({
        name: 'FTR_User_Defined_Homepage',
        value: homepage,
        expireDate,
        secure: false,
        path: '/',
      });
      setCookie({
        name: 'FTR_User_Defined_Homepage_Link',
        value: homepage,
        expireDate,
        secure: false,
        path: '/',
      });
    }
  };
  return (
    <a
      data-hydrate
      href={redirectedURL?.href}
      onClick={() => {
        setCountryCodeCookie(country.locale);
        setHomepageCookie(country.homepage);
      }}
      data-ignore-redirects
      data-analytics-id="locale-flag"
      className="locale-selector__option"
      {...{
        'data-component-tracking-label': trackingLabels?.includes(country.locale)
          ? country.name
          : null,
      }}
    >
      <div className="locale-selector__option__icon-container">
        <div className="locale-selector__option__icon locale-selector__option__icon--flag">
          <img
            className="locale-selector__image"
            src={country.image.src}
            alt={country.image.alt}
            loading="lazy"
          />
        </div>

        <span className={`locale-selector__option__name ${isChecked ? activeClassName : ''}`}>
          {country.name}
        </span>
      </div>

      <div
        className={`
          locale-selector__option__icon locale-selector__option__icon--tick 
          ${isChecked ? optionIconClass : ''}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M26.744 3L11.419 18.423l-6.163-6.201L0 17.509 11.419 29 32 8.289z" />
        </svg>
      </div>
    </a>
  );
};

const CountryOptions: React.FC<ICountryOptions> = ({
  countries,
  activeEdition,
  trailingSlash,
  trackingLabels,
  links,
}) => {
  const countriesElements = countries.map((country) => {
    return (
      <CountryOption
        key={country.name}
        {...{ activeEdition, trailingSlash, country, links, trackingLabels }}
      />
    );
  });

  return <>{countriesElements}</>;
};

export default CountryOptions;
