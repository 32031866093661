import React from 'react';

import { IDropdownTrigger } from '~/interfaces/locale-selector';

const DropdownTrigger: React.FC<IDropdownTrigger> = ({ setVisibility, displayLabel, image }) => {
  return (
    <button
      data-hydrate
      type="button"
      onClick={setVisibility}
      className="locale-selector__trigger"
      data-analytics-id="locale-flag-dropdown"
    >
      <span className="locale-selector__text">{displayLabel}</span>
      <div className="locale-selector__icon--flag">
        {image && <img src={image.src} alt={image.alt} loading="eager" />}
      </div>
      <div className="locale-selector__icon--chevron-down" />
    </button>
  );
};

export default DropdownTrigger;
