import React from 'react';

import { IContinentColumns } from '~/interfaces/locale-selector';

import ColumnTitles from '../ColumnTitles/ColumnTitles';
import CountryOptions from '../CountryOptions/CountryOptions';

const ContinentColumns: React.FC<IContinentColumns> = ({
  filteredData,
  isDesktop,
  countriesVisibility,
  activeEdition,
  trailingSlash,
  trackingLabels,
  links,
  setCountriesVisibility,
}) => {
  const columns = filteredData.map((continentData) => {
    const { continentName, countries } = continentData;
    const displayCountries = isDesktop ? true : countriesVisibility === continentName;

    return (
      <div className="locale-selector__dropdown__columns" key={continentName}>
        <ColumnTitles
          continent={continentName}
          isDesktop={isDesktop}
          setCountriesVisibility={setCountriesVisibility}
        />
        {displayCountries && (
          <CountryOptions
            countries={countries}
            activeEdition={activeEdition}
            trailingSlash={trailingSlash ?? false}
            trackingLabels={trackingLabels}
            links={links}
          />
        )}
      </div>
    );
  });

  return <>{columns}</>;
};

export default React.memo(ContinentColumns);
