import React, { useState } from 'react';

import { IColumnTitles } from '~/interfaces/locale-selector';

const ColumnTitles: React.FC<IColumnTitles> = ({
  continent,
  isDesktop,
  setCountriesVisibility,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  if (continent === '') {
    return null;
  }

  if (isDesktop) {
    return <span className="locale-selector__dropdown__title">{continent}</span>;
  }

  const openClassName: string = isOpen ? ' locale-selector__dropdown__title--active' : '';

  return (
    <button
      data-hydrate
      type="button"
      className={`locale-selector__dropdown__title${openClassName}`}
      onClick={() => {
        setCountriesVisibility((value) => (value === continent ? '' : continent));
        setOpen(!isOpen);
      }}
    >
      {continent}
      <div className="locale-selector__dropdown__icon--chevron-down" />
    </button>
  );
};

export default ColumnTitles;
