import React, { useEffect, useRef, useState } from 'react';

import { ILocaleSelector, ILocaleSelectorContinent } from '~/interfaces/components/LocaleSelector';

import debounce from '../utils/debounce';

import ContinentColumns from './inner-components/ContinentColumns/ContinentColumns';
import DropdownTrigger from './inner-components/DropdownTrigger/DropdownTrigger';
import {
  displayDropdown,
  getActiveCountry,
  getAllLinks,
  getDropdownLeftPosition,
  getDropdownRightPosition,
  getIsDesktop,
  removeHideFlagLocales,
} from './LocaleSelector.utils';
import '~/interfaces/fte.interface';

const LocaleSelector: React.FC<ILocaleSelector> = ({
  defaultEdition,
  l10ns,
  dropdownHeading,
  trailingSlash = false,
  trackingLabels = null,
  dropdownPosition = 'default',
}) => {
  const localeSelectorElement = useRef(null);

  const filteredData: ILocaleSelectorContinent[] = removeHideFlagLocales(l10ns);

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const activeCountry = getActiveCountry(defaultEdition, filteredData, isFirstRender);

  const [className, setClassName] = useState<string>('locale-selector');
  const [dropdownVisibility, setDropdownVisibility] = useState<boolean>(false);

  // countriesVisibility applies just for mobile
  const [countriesVisibility, setCountriesVisibility] = useState<string>('');

  const links: Array<string> = getAllLinks(filteredData);
  const isDesktop = getIsDesktop(filteredData.length, isFirstRender);

  useEffect(() => {
    setClassName((value) => (!isDesktop ? `${value} locale-selector--mobile` : value));

    if (filteredData.length > 3) {
      setClassName((value) => `${value} locale-selector--region-distribution`);
    }
  }, [isDesktop]);

  useEffect(() => {
    const closeOnResize = () => {
      setDropdownVisibility(false);
    };
    const debouncedCloseOnResize = debounce(closeOnResize, 50);
    // We don't want to add the event if the region selector is closed.
    if (dropdownVisibility) {
      window.addEventListener('resize', debouncedCloseOnResize);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const container: React.MutableRefObject<HTMLElement | null> = localeSelectorElement;
    if (container !== null) {
      container?.current?.style.setProperty(
        '--locale-selector__dropdown-left',
        getDropdownLeftPosition(
          isDesktop ? dropdownPosition : 'center',
          localeSelectorElement.current,
        ),
      );

      container?.current?.style.setProperty(
        '--locale-selector__dropdown-right',
        getDropdownRightPosition(isDesktop ? dropdownPosition : 'center'),
      );
    }

    return () => {
      window.removeEventListener('resize', debouncedCloseOnResize);
    };
  }, [dropdownVisibility, localeSelectorElement]);

  if (!displayDropdown(filteredData)) {
    return null;
  }

  return (
    <div data-hydrate className={className} ref={localeSelectorElement}>
      <DropdownTrigger
        setVisibility={() => {
          setDropdownVisibility((value) => !value);
          setCountriesVisibility('');
        }}
        displayLabel={activeCountry?.displayLabel}
        image={activeCountry?.image}
      />
      <div
        className="locale-selector__dropdown"
        style={dropdownVisibility ? {} : { display: 'none' }}
      >
        {dropdownHeading && (
          <div className="locale-selector__dropdown__heading">{dropdownHeading}</div>
        )}
        <div className="locale-selector__dropdown__continents">
          <ContinentColumns
            filteredData={filteredData}
            isDesktop={isDesktop}
            countriesVisibility={countriesVisibility}
            activeEdition={activeCountry?.locale}
            trailingSlash={trailingSlash}
            trackingLabels={trackingLabels}
            links={links}
            setCountriesVisibility={setCountriesVisibility}
          />
        </div>
      </div>
    </div>
  );
};

export default LocaleSelector;
