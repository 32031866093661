import {
  ILocaleSelectorContinent,
  ILocaleSelectorCountry,
} from '~/interfaces/components/LocaleSelector';

import { getCookie } from '../utils/cookies';
import '~/interfaces/fte.interface';

export function removeHideFlagLocales(l10ns: ILocaleSelectorContinent[]) {
  const filteredData: ILocaleSelectorContinent[] = [];

  l10ns.forEach((continentData) => {
    const filteredCountries: ILocaleSelectorCountry[] = [];

    continentData.countries.forEach((countryData) => {
      if (!countryData.hideFlag) {
        filteredCountries.push(countryData);
      }
    });

    if (filteredCountries.length) {
      filteredData.push({
        continentName: continentData.continentName,
        countries: filteredCountries,
      });
    }
  });

  return filteredData;
}

const cookieRefinesLocale = (localeInCookie: string, continents: ILocaleSelectorContinent[]) =>
  continents
    .flatMap((continent) => continent.countries)
    .some(
      (country) => country.locale === localeInCookie && country.aliasOf === window?.ffte?.locale,
    );

export function getActiveCountry(
  defaultEdition: string,
  continents: ILocaleSelectorContinent[],
  isFirstRender = false,
): ILocaleSelectorCountry | undefined {
  let activeEdition = defaultEdition;

  // This condition ensures that the cookie is ignored during the initial render on the client,
  // thus producing the same markup as SSR, which is important for hydration.
  if (!isFirstRender) {
    const localeInCookie = getCookie('FTR_User_Defined_Country_Code');

    if (localeInCookie && cookieRefinesLocale(localeInCookie, continents)) {
      activeEdition = localeInCookie;
    } else if (typeof window !== 'undefined' && window?.ffte?.locale) {
      activeEdition = window.ffte.locale;
    }
  }

  if (activeEdition === 'UK') {
    activeEdition = 'GB';
  }

  const findCountryByLocale = (locale: string) =>
    continents
      .flatMap((continent) => continent.countries)
      .find((country) => country.locale === locale);

  return findCountryByLocale(activeEdition) || findCountryByLocale(defaultEdition);
}

export function displayDropdown(filteredData: ILocaleSelectorContinent[]) {
  if (!filteredData.length) return false;

  let activeFlags = 0;

  // We don't need to display the dropdown if we just have one country available.
  filteredData.forEach((continentData) => {
    activeFlags += continentData.countries.length;
  });

  return activeFlags > 1;
}

export const getAllLinks = (filteredData: ILocaleSelectorContinent[]): Array<string> => {
  const links: Array<string> = [];

  filteredData.forEach((continentData) => {
    continentData.countries.forEach((country) => {
      if (country.link !== '') {
        links.push(country.link);
      }
    });
  });

  return links;
};

export const getBreakpoint = (filteredDataLength: number): string => {
  if (filteredDataLength > 3) {
    return '(min-width: 1000px)';
  }

  return '(min-width: 700px)';
};

export const getIsDesktop = (filteredDataLength: number, isFirstRender: boolean): boolean => {
  // The `!isFirstRender` condition ensures that the window size is ignored during the initial
  // render on the client, thus producing the same markup as SSR, which is important for hydration.
  return !isFirstRender && typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'
    ? window.matchMedia(getBreakpoint(filteredDataLength)).matches
    : true;
};

export const getDropdownLeftPosition = (position: string, element: any): string => {
  switch (position) {
    case 'center': {
      if (!element) return 'unset';
      const parentPosition = element.getBoundingClientRect();
      const leftValue = parentPosition.left;

      return `calc((-${leftValue}px) / 2 + (100vw - ${leftValue}px) / 2)`;
    }
    case 'left':
      return 'unset';
    default:
    case 'right':
      return '0px';
  }
};

export const getDropdownRightPosition = (position: string): string => {
  switch (position) {
    case 'center':
      return 'unset';
    case 'left':
      return '0px';
    default:
    case 'right':
      return 'unset';
  }
};
